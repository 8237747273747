.container {
  height: 100vh;
  width: 100vw;
}

.body {
  background-image: linear-gradient(0deg, rgba(0, 91, 255, 0.1727284663865546) 0%, rgba(255, 255, 255, 1) 59%);
  padding: 10vh 10vw;
  display: flex;
  justify-content: space-around;
  overflow-y: auto;
  min-height: 100vh;
}

.center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.img {
  position: fixed;
  max-width: 50vw;
  max-height: 50vh;
  padding: 5vh 0 5vh 0;
}

.text {
  color: #1d1d1d;
  width: 40vw;
  line-height: 1.5;
}

.textUnderForm {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 8rem;
  color: #1d1d1d;
  font-size: 18px;
  line-height: 143%;
  letter-spacing: 0.018em;
}

.textUnderFormSignup {
  @extend .textUnderForm;
  margin-top: -3rem;
  margin-bottom: 0.5rem;
}

.link {
  color: #005BFF !important;
  font-weight: 700;
}

.link:hover {
  color: #0250DD !important;
}

@media (max-width: 1025px) and (orientation: portrait) {
  .body {
    display: flex;
    justify-content: center;
  }

  .text,
  .img,
  .left {
    display: none;
  }

  .textUnderForm {
    text-align: center;
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .body {
    display: flex;
    justify-content: center;
    overflow-y: auto;
  }

  .textUnderForm {
    padding-bottom: 30px;
  }
}