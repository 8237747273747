.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar {
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.logo {
  width: 12rem;
  padding: 1rem;
}
.signupBtn,
.loginBtn {
  width: 120px;
  height: 40px;
  padding: 6px 16px;
  border-radius: 99px;
  font-weight: 600;
  font-family: "Montserrat";
}
.signupBtn {
  color: #1d1d1d;
  border-width: 2px;
  border-color: #1d1d1d;
}
.loginBtn {
  background-color: #005BFF;
  border: none;
  color: #fff;
  margin: 0 12px;
}
.logo:hover,
.signupBtn:hover,
.loginBtn:hover {
  cursor: pointer;
}

.loginBtn:disabled {
  background-color: #6c757d;
  border-color: #f8f9fa;
  color: #f8f9fa;
}
@media (max-width: 600px) {
  .signupBtn,
  .loginBtn {
    display: none;
  }

  .navbar {
    padding: 0.3rem;
  }
}
