.react-datepicker__input-container.react-datepicker__view-calendar-icon {
  @apply flex flex-row-reverse items-center;
}
/* .react-datepicker__triangle {
  transform: translateX(120px) !important;
} */

/* Set datepicker size by changing font and spacing sizes */
.react-datepicker__month-container .react-datepicker__header {
  padding-top: 12px;
  padding-bottom: 12px;
}
.react-datepicker {
  font-size: 1.25em !important;
}
.react-datepicker__header {
  background-color: white !important;
  padding-top: 0.8em;
  font-family: 'Montserrat', Helvetica, Arial, apple-system, sans-serif;
}
.react-datepicker__month {
  margin: 0.5em 0.7em !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  font-family: 'Montserrat', Helvetica, Arial, apple-system, sans-serif;
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em;
}
.react-datepicker__day--outside-month {
  color: rgb(189, 189, 189) !important;
}
.react-datepicker__current-month {
  font-size: 1.25em !important;
}
.react-datepicker__navigation {
  top: 1.25em !important;
  line-height: 1.7em;
  border: 0.45em solid transparent;
  transform: scale(1.5);
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #005bff !important;
  color: #fff !important;
}
/* .custom-datepicker {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.react-datepicker {
  border: none !important;
  background-color: none !important;
}

.react-datepicker-popper {
  position: relative !important;
  transform: none !important;
  padding-top: 0 !important;
}

.react-datepicker-wrapper {
  position: absolute;
}

.react-datepicker__header {
  background-color: white !important;
  border: none !important;
}

.custom-datepicker-header {
  border-bottom: 1px solid #efefef;
  padding: 0 10px 12px;
  margin-bottom: 8px;
}

.custom-datepicker-header .custom-datepicker-header__year {
  width: 65px;
}

.custom-datepicker-header .custom-datepicker-header__month {
  width: 100px;
}

.react-datepicker__header {
  padding-bottom: 0 !important;
}

.react-datepicker__month-container {
  padding: 0 !important;
}

.react-datepicker__day {
  font-size: 16px !important;
  font-weight: 300;
}

.react-datepicker__day-name {
  color: #a9a9a9 !important;
}

.react-datepicker__day--outside-month {
  color: #e7e7e7 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day--selected {
  border-radius: 9999px !important;
  background-color: var(--colors-green) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 36px !important;
  line-height: 36px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 9999px !important;
}

.react-datepicker__month {
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
}

.custom-datepicker-time-format {
  background-color: #efefef;
  color: black;
  border: none;
  outline: none;
  font-size: 16px;
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  margin-left: 6px;
  line-height: 40px;
}

.time-format-container {
  margin-bottom: 12px;
}

.custom-datepicker-time {
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 0 10px;
}

.custom-datepicker-time input {
  border: none;
  outline: none;
  height: 40px;
  background-color: none;
  width: 24px;
  text-align: center;
  border-radius: 6px;
  font-size: 16px;
  line-height: 40px;
}

.custom-datepicker-time span {
  margin: 0 6px;
}

.custom-datepicker-button {
  background-color: var(--colors-green);
  height: 40px;
  border: none;
  outline: none;
  width: 100%;
} */
